import { graphql } from 'gatsby'
import { CanonicalUrlComponent } from 'commons/CanonicalUrlComponent'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import DynamicComponent, {
  ComponentDataModel,
} from 'components/DynamicComponent/DynamicComponent'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import { PageDataContext } from 'contexts/PageDataContext'
import { Helmet } from 'react-helmet'
import Layout from '../Layout'
import { PageDataBaseType } from 'models/page-model'
import { isAttivazioneCustomFe } from 'components/DynamicComponent/map/ParagraphAttivazioneCustomFe'
import classNames from 'classnames'

export default function PageDefault(props: PageDataProps) {
  const {
    data: { page },
    pageContext: { title },
    location,
  } = props

  const hasSimpleHeader = page.field_header_pagina === 'header_without_menu'

  return (
    <A2ADataContextProvider>
      <Layout
        pageTitle={page.field_meta_tags_fe?.title || title}
        simpleHeader={hasSimpleHeader}
        defaultMarginTop={page.field_abilita_margin_footer || false}
        footerType={page.field_menu_footer_newsite ? 'simple' : 'main'}
      >
        <Helmet
          bodyAttributes={{
            class: classNames('a2a-template-default', 'a2a-navigation-main', {
              'a2a-navigation-full': !hasSimpleHeader,
            }),
          }}
        >
          {page?.field_meta_tags_fe?.description && (
            <meta
              name="description"
              content={page?.field_meta_tags_fe?.description}
            />
          )}
        </Helmet>

        <CanonicalUrlComponent url={page?.path?.alias} />

        <PageDataContext.Provider
          value={{
            data: page,
          }}
        >
          {page.relationships.field_breadcrumb_content?.field_link_breadcrumb &&
            page.relationships.field_breadcrumb_content?.field_link_breadcrumb
              ?.length > 0 && (
              <Breadcrumbs
                links={[
                  ...page.relationships.field_breadcrumb_content
                    .field_link_breadcrumb,
                  { title: page.title, uri: page.path.alias },
                ]}
              />
            )}
          {page.relationships?.field_struttura_content &&
            page.relationships.field_struttura_content.map((field, i) => {
              // Disable rendering of paragraph__attivazione_custom_fe
              if (isAttivazioneCustomFe(field)) {
                return null
              }

              const prevSibling =
                page.relationships.field_struttura_content[i - 1] ?? undefined
              const nextSibling =
                page.relationships.field_struttura_content[i + 1] ?? undefined

              if (
                i === page.relationships.field_struttura_content.length - 1 &&
                location.hash?.length
              ) {
                const element_to_scroll_to = document.getElementById(
                  location.hash.replace('#', '')
                )
                if (element_to_scroll_to) element_to_scroll_to.scrollIntoView()
              }

              return (
                <DynamicComponent
                  key={i}
                  component={field.__typename}
                  data={field}
                  attributes={
                    prevSibling && isAttivazioneCustomFe(prevSibling)
                      ? {
                          attivazioneCustomFe: prevSibling,
                        }
                      : undefined
                  }
                  related={{
                    parent: undefined,
                    siblings: {
                      before: prevSibling,
                      after: nextSibling,
                    },
                  }}
                />
              )
            })}
        </PageDataContext.Provider>
      </Layout>
    </A2ADataContextProvider>
  )
}

interface PageDataProps {
  data: {
    page: PageDataType
  }
  pageContext: {
    title: string
    drupal_id: string
  }
  location: Location
}

interface PageDataType extends PageDataBaseType {
  id: string
  drupal_id: string
  internal: {
    type: 'a2a_newsite__content_new_site'
  }
  title: string
  status: boolean
  field_is_homepage: boolean
  field_header_pagina?: string | null
  field_abilita_margin_footer?: boolean | null
  field_menu_footer_newsite?: boolean | null
  path: {
    alias: string
  }
  field_meta_tags_fe: {
    title: string
    description: string
  }
  relationships: {
    field_breadcrumb_content?: {
      __typename: string
      id: string
      drupal_id: string
      internal: {
        type: string
      }
      parent_type: string
      parent_field_name: string
      field_link_breadcrumb:
        | {
            title: string
            uri: string
          }[]
        | null
    } | null
    field_struttura_content: ComponentDataModel[]
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    page: a2ANewsiteContentNewSite(id: { eq: $id }) {
      ...A2AContentNewSiteFragment
    }
  }

  fragment A2AContentNewSiteFragment on a2a_newsite__content_new_site {
    id
    drupal_id
    title
    status
    field_is_homepage
    field_header_pagina
    field_abilita_margin_footer
    field_menu_footer_newsite
    path {
      alias
    }
    field_meta_tags_fe {
      title
      description
    }
    internal {
      type
    }
    relationships {
      field_breadcrumb_content {
        ...BreadcrumbsFe
      }
      field_struttura_content {
        __typename
        ...ParagraphAdditionalBannerFe
        ...ParagraphArticoliFe
        ...ParagraphAttivazioneCustomFe
        ...ParagraphBannerChiamateSospetteFe
        ...ParagraphBannerFe
        ...ParagraphCategorieCarouselFe
        ...ParagraphConfiguratoreStartFr
        ...ParagraphDarksiteFe
        ...ParagraphDarksiteWrapperFe
        ...ParagraphDoorwaysWrapperFe
        ...ParagraphDoorwaysBannerWrapperFe
        ...ParagraphElevatorPitchFe
        ...ParagraphGalleryFe
        ...ParagraphHeroWrapperFe
        ...ParagraphLinkUtiliFe
        ...ParagraphMarginiFe
        ...ParagraphModaleFe
        ...ParagraphNoteFe
        ...ParagraphPreFooterFe
        ...ParagraphRiferimentoMagazineFe
        ...ParagraphSezioneFe
        ...ParagraphSezioneSeparatoreFe
        ...ParagraphSezioneVisual
        ...ParagraphSezioneWrapperDueColonneFe
        ...ParagraphStazioniDiRicarica
        ...ParagraphStickyBarFe
        ...ParagraphStoreLocator
        ...ParagraphTabsFe
        # ...ParagraphTabellaFe # Not used
        ...ParagraphTestoLiberoFe
        ...ParagraphUseCasesSmartCity
        ...ParagraphWrapperTabsVerticalFe
      }
    }
  }
`
